import { authTypeEnum } from "../../../../core/enums/fp-auth-type.enum";
import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { paymentErrorEnum } from "../../../../core/enums/fp-payment-error.enum";
import { fpCreatePurchaseAsync } from "../../../../core/requests/purchase/fp-create-purchase";
import { fpExecutePurchaseAsync } from "../../../../core/requests/purchase/fp-execute-purchase";
import { fpGetJourneyConfig } from "../../../../core/utils/fp-get-journeys-config";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpGetStorageValue, fpSetStorageValue } from "../../../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";
import { fpAuthenticationActionAsync } from "../../../authentication/fp-auth-actions";
import { fpStripeSetCardElements } from "./fp-stripe-card-element";
import { fpStripeConfirmActionAsync } from "./fp-stripe-confirm-action";
import { fpStripeConfirmCardActionAsync } from "./fp-stripe-confirm-card-action";
import { fpStripeOnError } from "./fp-stripe-error-handler";

export async function fpProcessPaymentWithStripe24Async(paymentIntentId, paymentClientSecret, paymentMethodType, elements, billingDetails) {
    try {
        // Validate payment methods
        const isValid = fpStripeValidatePaymentMethod(elements, paymentMethodType);
        if (!isValid) {
            fpStripeOnError({ error: { code: "GenericCard" } });
            return false;
        }

        const request = {
            payment_intent_id: paymentIntentId,
            billing_details: billingDetails ?? null,
            payment_method_type: paymentMethodType
        };

        // If simple checkout enabled(by config) a user needs to be signed up before a purchase can be created       
        // Simple Checkout should be enabled only for the simplest flows without any auth validations or social auth 
        const fpJourneyConfig = fpGetJourneyConfig();

        if (fpJourneyConfig?.simple_checkout && (!_fp.user || !_fp.user.access_token)) {
            const response = await fpAuthenticationActionAsync(authTypeEnum.SilentSignUp);
            if (!response?.success) {
                console.error("FP:: User not signed up properly");
                return false;
            }
        }

        _fp.metadata.purchase = await fpCreatePurchaseAsync(request);
        fpTrackUserAction("fp_purchase_action_required", null, paymentMethodType, _fp.metadata.purchase?.id, null);
        fpSpinnerHandler("show", null);

        const stripeConfirmType = paymentClientSecret?.includes("seti_") ? "setup" : "payment";
        const stripeConfirmResponse = paymentMethodType === "card"
            ? await fpStripeConfirmCardActionAsync(paymentClientSecret, elements, stripeConfirmType)
            : await fpStripeConfirmActionAsync(paymentClientSecret, elements, paymentMethodType, stripeConfirmType);

        if (!stripeConfirmResponse || stripeConfirmResponse.error) {
            fpSpinnerHandler("hide", null);
            fpTrackUserAction("purchase_failed", null, "purchase", _fp.metadata.purchase?.id, stripeConfirmResponse?.error);
            if (_fp.metadata.journey === journeyTypeEnum.OrderSummary) {
                fpTriggerJourney(journeyTypeEnum.OrderSummary);
            }

            throw { error: stripeConfirmResponse?.error ?? paymentErrorEnum.Generic };
        }

        if (stripeConfirmResponse.paymentIntent || stripeConfirmResponse.setupIntent) {
            let data = stripeConfirmResponse.setupIntent
                ? { setup_intent_id: stripeConfirmResponse.setupIntent.id }
                : { payment_intent_id: stripeConfirmResponse?.paymentIntent?.id };

            const executeResponse = await fpExecutePurchaseAsync(_fp.metadata.purchase.id, data);
            _fp.metadata.purchase = executeResponse;

            fpUpdateUserMetadata(executeResponse);
            fpTrackUserAction("fp_purchase_resolved", null, "purchase", executeResponse.id, executeResponse);
        }

        fpSpinnerHandler("hide", null);
        return true;
    } catch (err) {
        fpSpinnerHandler("hide", null);
        fpTrackUserAction("fp_error", null, "purchase", null, "Generic error");
        fpStripeOnError(err);

        return false;
    }
}

function fpStripeValidatePaymentMethod(elements, paymentMethodType) {
    let isValid = true;
    if (paymentMethodType !== "card") { // Cannot validate other payment methods atm
        return isValid;
    }

    let cardElements = fpStripeSetCardElements();
    cardElements?.forEach(element => {
        if (!isValid) { return; }
        isValid = elements.getElement(element.type)?._complete;
    });

    return isValid;
}

function fpUpdateUserMetadata(executeResponse) {
    if (!executeResponse?.executed || !executeResponse?.renewable || !_fp.user?.access_token) {
        return;
    }

    _fp.user.subscriber = true;
    const cookieUser = fpGetStorageValue("fp.user");
    if (!cookieUser) {
        return;
    }

    cookieUser.subscriber = true;
    fpSetStorageValue("fp.user", cookieUser);
}
import { errorMessageEnum } from "../../../../core/enums/fp-error-message.enum";
import { fpGetJourneyConfig } from "../../../../core/utils/fp-get-journeys-config";
import { fpSnackbarMessageHandler } from "../../../../core/utils/fp-snackbar";

export function fpStripeValidatePaymentForm() {
    const fpJourneyConfig = fpGetJourneyConfig();
    if (!fpJourneyConfig) {
        return true;
    }

    // If simple checkout enabled(in config) and a user is not signed up, payment form needs to be validated whether all
    // mandatory fields are populated. As we will need to sign up customer later(simple checkout shouldn't be enabled if social auth is allowed)
    if (fpJourneyConfig.simple_checkout && (!_fp.user || !_fp.user.access_token) && !fpValidatePaymentForm()) {
        return false;
    }

    const isAnonPurchase = _fp?.metadata?.selected_price?.allow_anonymous && (!_fp.user || !_fp.user.access_token);
    if (!isAnonPurchase) {
        return true;
    }

    const paymentFormEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='voluntary_contribution_form']");
    const isFormValid = fpValidatePaymentForm();
    if (!isFormValid || !paymentFormEl) {
        console.error("FP:: Invalid/Missing payment form");
        return false;
    }

    return true;
}

function fpValidatePaymentForm() {
    const paymentFormEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='voluntary_contribution_form']");
    if (!paymentFormEl) {
        console.error("FP:: payment form element not found");
        return false;
    }

    // Validate the payment form only if a password required, otherwise we can collect all data reuqired
    const formData = Object.fromEntries(new FormData(paymentFormEl).entries());
    const checkValidity = !!Object.keys(formData).find(x => x === "password");
    if (!checkValidity) {
        return true;
    }

    const isFormValid = paymentFormEl ? paymentFormEl.checkValidity() : false;
    if (!isFormValid) {
        fpSnackbarMessageHandler(null, "Mandatory Fields Not Populated", "error");
        const invalidEls = paymentFormEl?.querySelectorAll(":invalid");
        invalidEls?.forEach(el => {
            let errEl = el.parentElement.querySelector(".fp-control-error");
            errEl = errEl ? errEl : el.parentElement?.parentElement.querySelector(".fp-control-error");
            if (errEl) {
                errEl.innerHTML = errorMessageEnum.MandatoryField;
            }
        });
    }

    return isFormValid;
}
